import MatrixGallery_MatrixGallerySeparateTextBoxSkinComponent from '../components/NativeGalleries/MatrixGallery/viewer/skinComps/MatrixGallerySeparateTextBoxSkin/MatrixGallerySeparateTextBoxSkin.skin';
import MatrixGallery_MatrixGallerySeparateTextBoxSkinController from '../components/NativeGalleries/MatrixGallery/viewer/MatrixGallery.controller';


const MatrixGallery_MatrixGallerySeparateTextBoxSkin = {
  component: MatrixGallery_MatrixGallerySeparateTextBoxSkinComponent,
  controller: MatrixGallery_MatrixGallerySeparateTextBoxSkinController
};


export const components = {
  ['MatrixGallery_MatrixGallerySeparateTextBoxSkin']: MatrixGallery_MatrixGallerySeparateTextBoxSkin
};

